<template>
    <v-app>
        <v-row>
            <v-col cols="12">
                <div class="card card-custom " >
                    <div class="card-body p-0">
                        <div
                                class="wizard wizard-2"
                                id="kt_wizard_v2"
                                data-wizard-state="step-first"
                                data-wizard-clickable="true"
                        >
                            <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                                <div class="row">
                                    <div class="col-6">
                                        <h3>
                                            Submit Assignment
                                        </h3>
                                        <router-link :to="{name:'dashboard'}">
                                            Dashboard
                                        </router-link> \
                                        Submit Assignment
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </v-col>
            <v-col cols="12">
              <v-card flat class="mb-3">
                <v-card-title>
                  <div class="d-flex flex flex-column flex-md-row justify-content-between mr-3">
                    <a
                        href="#"
                        v-if="assignment"
                        class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                    >{{assignment.title}}</a
                    >
                    <div class="my-lg-0 my-3">
                      <strong v-if="assignment.total_marks">Total Marks : {{assignment.total_marks}}</strong>
                      <strong class="ml-2" v-if="assignment.pass_marks">Pass Marks :
                        {{assignment.pass_marks}}</strong>
                    </div>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-card-subtitle>
                    <span v-if="assignment.course"> Course : <strong>{{ assignment.course.title || 'N/A' }} |  </strong></span>
                    <span v-if="assignment.course"> Program : <strong>{{ assignment.course.program.title|| 'N/A' }} |  </strong></span>
                    <span v-if="assignment.course"> Grade : <strong>{{ assignment.course.grade.title|| 'N/A' }} |  </strong></span>
                    <span v-if="assignment.submission_type"> Submission Type : <strong class="text-uppercase">{{ assignment.submission_type | format_status | capitalize   }}   </strong></span>
                    <br>
                    <span class=" d-block my-md-0 my-1" v-if="assignment.enable_submission_date"><b> Submission Starts on : </b> <strong class="text-primary">{{ assignment.allow_submission_date | moment("hh:mm a, dddd, MMMM Do YYYY") ||  'N/A' }}   </strong></span>
                    <span class=" d-block mb-md-0 mb-1" v-if="assignment.enable_due_date ">  <b>Due Date :</b> <strong class="text-danger">{{ assignment.due_date | moment("hh:mm a, dddd, MMMM Do YYYY") ||  'N/A' }}   </strong></span>

                  </v-card-subtitle>
                </v-card-text>
              </v-card>


            </v-col>
        </v-row>
      <template>
        <div>
          <v-expansion-panels
              v-model="panel"
              multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header>Detail</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p v-html="assignment.description" class="font-weight-bold text-dark-50">
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>Submission</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <router-link
                      class="text-black ml-2 font-size-lg " depressed
                      :to="{ name:'assignment-summary',params:{assignment_id: assignment.id}}">
                    Back to Assignment Detail
                  </router-link>
                </v-col>
                <v-alert
                    v-if="!assignment.can_submit"
                    dense
                    border="left"
                    type="warning"
                >
                  Assignment Due Date is passed. <span>{{ assignment.last_submission | moment("calendar")}}</span>
                </v-alert>
                <v-col cols="12" v-if="assignment">
                  <attachment @refresh="getMedia"></attachment>
                </v-col>
                <v-col cols="12" v-if="assignment">
                  <label>Submit Answer</label>
                  <ckeditor v-model="assignment_submission.assignment_text"></ckeditor>
                </v-col>
                <v-col cols="12" class="p-10">
                  <h5>Assessment declaration</h5>
                  <ul>
                    <li>This assessment is my/our original work and no part of it has been copied from any other source except where due acknowledgement is made.</li>
                    <li>I have not impersonated, or allowed myself to be impersonated by any person for the purposes of this assessment.</li>
                    <li>No part of this assessment has been written for me/us by any other person except where such collaboration has been authorised by the lecturer/teacher concerned.</li>
                    <li>Where this work is being submitted for individual assessment, I declare that it is my original work and that no part has been contributed by, produced by or in conjunction with another student.</li>
                    <li>I give permission for my assessment response to be reproduced, communicated compared and archived for the purposes of detecting plagiarism.</li>
                    <li>I give permission for a copy of my assessment to be retained by the educational organisation for review and comparison, including review by external examiner</li>
                  </ul>
                  <h5>I understand that:</h5>
                  <ul>
                    <li>Plagiarism is the presentation of the work, idea or creation of another person as though it is your own. It is a form of cheating and is a very serious academic offence that may lead to exclusion from the University. Plagiarised material can be drawn from, and presented in, written, graphic and visual form, including electronic data and oral presentations. Plagiarism occurs when the origin of the material used is not appropriately cited.</li>
                    <li>Plagiarism includes the act of assisting or allowing another person to plagiarise or to copy my work.</li>
                  </ul>
                  <div class="border border-dark pt-3 pl-5">
                    <label> I have read and understood the Declaration and Statement of Authorship above. Please tick to continue</label>
                    <v-checkbox v-model="assignment_submission.agreed">
                      <template v-slot:label>
                      </template>
                    </v-checkbox>
                    <span class="text-danger"
                          v-if="$v.assignment_submission.agreed.$error">Please Checked Declaration</span>
                  </div>

                </v-col>
                <v-col cols="12">
                  <router-link
                      class="text-black ml-2 font-size-lg " depressed
                      :to="{ name:'assignment-summary',params:{assignment_id: assignment.id}}">
                    Back to Assignment Detail
                  </router-link>
                </v-col>
                <v-col cols="12" class="col-12 text-right mt-4">
                  <v-btn
                      :disabled="!assignment.can_submit"
                      class="text-white ml-2 btn btn-primary"
                      depressed
                      @click="createAndUpdate"
                      :loading="isBusy"
                  >Submit
                  </v-btn>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </template>

    </v-app>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import {AssignmentMixin} from "@/mixins/AssignmentMixin";
    import Attachment from "./AttachmentSubmit";
    import AssignmentAttachmentService from "@/core/services/assignement/AssignmentAttachmentService";
    import AssignmentSubmissionService from "@/core/services/assignement/AssignmentSubmissionService";

    const assignmentAttachmentService = new AssignmentAttachmentService();

    const assignmentSubmissionService = new AssignmentSubmissionService();

    export default {
        name: "SubmitAssignment",
        mixins: [AssignmentMixin],
        components: {Attachment},
        validations: {
            assignment_submission: {
                assignment_id: {required},
                student_id: {required},
                agreed: {required},
            }
        },
        computed: {
            assignment_id() {
                return this.$route.params.assignment_id;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },
            validationRules() {
                let rule = {};
                rule.assignment_id = {required};
                rule.student_id = {required};
                rule.agreed = {required};
                if (this.assignment && this.assignment.submission_type == 'online_text') {
                    rule.assignment_text = {required}
                }

                return {
                    assignment_id: {required},
                    student_id: {required},
                    agreed: {required},
                }
            }
        },

        data() {
            return {
                dialog: false,
                menu: false,
                isBusy: false,
                edit: false,
                total: null,
                perPage: null,
                page: null,
                medias: null,
                attachment_ids: [],
                search: {
                    type: "submission"
                },
                panel: [ 1],
                assignment_submission: {
                    assignment_id: null,
                    student_id: null,
                    submission_type: null,
                    agreed: null,
                }
            };
        },
        mounted() {
            this.getSingleAssignment(this.assignment_id);
            this.checkDueDate();
            this.assignment_submission.assignment_id = this.assignment_id;
            this.assignment_submission.student_id = this.currentUser.id;
            this.search.student_id = this.currentUser.id;
            this.getMedia();
            this.getByAssignmentSubmission();


        },
        methods: {
            checkDueDate() {
                var today = this.$moment(new Date()).format("D, M, YY");
                var due_date = this.$moment(this.assignment.due_date).format("D, M, YY");
                if (due_date <= today) {
                    return false;
                } else {
                    return true;
                }
            },
            showModal() {
                this.dialog = true;
            },
            hideModal() {
                this.dialog = false;
            },
            getByAssignmentSubmission() {
                assignmentSubmissionService.getByAssignmentAndStudentId(this.assignment_id).then(response => {
                    this.assignment_submission = response.data.submission;
                    if (this.submission.submission_type === 'file_submission') {

                        this.getMedia();
                    }
                });
            },
            createAndUpdate() {
                this.$v.$touch();
                if (this.$v.assignment_submission.$error) {
                    setTimeout(() => {
                        this.$v.$reset();
                    }, 3000);
                } else {
                    this.$confirm({
                        message: `You are about to submit your assignment. Do you wish to continue ?`,
                        button: {
                            no: "No",
                            yes: "Yes"
                        },
                        callback: confirm => {
                            if (confirm) {
                                this.assignment_submission.submission_type = this.assignment.submission_type;
                                if (this.assignment_submission.submission_type == 'file_submission')
                                    this.assignment_submission.submission_attachments_ids = this.attachment_ids.join(',');
                                if (this.assignment_submission.id) {
                                    this.__update();
                                } else {
                                    this.__create();
                                }
                            }
                        }
                    });

                }
            },
            getMedia(index = null) {
                this.$bus.emit('toggleLoader');
                assignmentAttachmentService
                    .paginate(this.assignment_id, this.search, this.page)
                    .then(response => {
                        this.medias = response.data.data;
                        if (this.medias.length) {
                            this.attachment_ids = this.medias.map(media => {
                                return media.id;
                            })
                        }
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                    })
                    .catch((err) => {
                        // console.log(err)
                    }).finally(() => {
                    this.$bus.emit('toggleLoader');
                });
            },
            __create() {
                this.isBusy = true;
                assignmentSubmissionService.store(this.assignment_id, this.assignment_submission).then(response => {
                    this.isBusy = false;
                    this.resetForm()
                    this.$router.push({ name:'assignment-summary',params:{assignment_id: this.assignment_id}})
                    this.$snotify.success("Created successfully");
                });
            },
            __update() {
                this.isBusy = true;
                assignmentSubmissionService.update(this.assignment_id, this.assignment_submission.id, this.assignment_submission).then(response => {
                    this.isBusy = false;
                    this.resetForm()
                    this.$router.push({ name:'assignment-summary',params:{assignment_id: this.assignment_id}})
                    this.$snotify.success("Updated successfully");

                });
            }, resetForm() {
                this.assignment_submission = {
                    assignment_id: null,
                    student_id: null,
                    agreed: null,
                }
                this.$v.$reset()
            }
        },
    }
</script>

<style scoped>

</style>
