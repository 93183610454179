<template>
    <v-app>
        <div v-if="error">
            <v-row>
                <v-col cols="12">
                    <v-alert
                            border="right"
                            dismissible
                            colored-border
                            type="error"
                            elevation="2"
                    >PDF, DOC, JPEG, PNG, PPT, XLS & XLSX files are allowed to upload.
                    </v-alert>
                </v-col>
            </v-row>
        </div>
      <v-row  v-if="mediaLoading">
        <v-col cols="4">
          <v-skeleton-loader

              class="mx-auto"
              max-width="300"
              type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader

              class="mx-auto"
              max-width="300"
              type="card"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader

              class="mx-auto"
              max-width="300"
              type="card"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <div class="row media-gallery mt-5 " v-if="medias.length">

        <div class="col-md-12 m-1 preview-images" v-for="(image, index) in medias" :key="index">

          <img  src="/media/svg/files/copy.png" :alt="`Image Uploader ${index}`"  style="height: 24px; text-align: left"/>
          <span class="name ml-2 mr-10">{{ image.title }}</span>
          <a :href="image.path" target="_blank" class="mr-3">
            <i class="fas fa-eye blue-grey--text" title="View"></i> View
          </a>
          <a href="#" @click.prevent="deleteImg(image.id)">
            <i class="fas fa-trash red--text" title="Delete"></i> Delete
          </a>
        </div>
      </div>
        <v-alert
                v-if="checkSize"
                border="right"
                dismissible
                colored-border
                type="error"
                elevation="2"
        >File Exceed the Given size
        </v-alert>
        <v-row>
            <v-col cols="12">
                <div class="upload-control" v-if="images  && images.length">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-md-2" v-for="image in images">
                                    <img :src="image" style="height: 50px; width: 50px;"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 text-right">
                            <span class="text-white text-left">Total Files: {{images.length}}</span><br/>
                            <v-btn
                                    type="submit"
                                    color="white--text v-btn theme--light elevation-2 v-size--large primary"
                                    class="btn-standard"
                                    text
                                    @click="upload"
                                    :loading="isBusy"
                            >Start Upload
                            </v-btn>
                        </div>

                    </div>


                </div>

                <div
                        class="uploader"
                        @dragenter="OnDragEnter"
                        @dragleave="OnDragLeave"
                        @dragover.prevent
                        @drop="onDrop"
                        :class="{ dragging: isDragging }"
                >

                    <div>
                        <i class="fa fa-cloud-upload"></i>
                        <p>Drag your Files here</p>
                        <div>OR</div>
                        <div class="file-input">
                            <label for="file">Select a file</label>
                            <input type="file" id="file" @change="onInputChange" multiple/>
                        </div>
                    </div>


                </div>

            </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="500">
            <v-card>
                <v-card-title class="headline">Use this URL</v-card-title>

                <v-card-text v-if="url">{{ url }}</v-card-text>

                <v-card-actions></v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" :timeout="timeout">
            {{ text }}
            <v-btn color="blue" text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
    </v-app>
</template>

<script>
    import AssignmentAttachmentService from "@/core/services/assignement/AssignmentAttachmentService";
    const assignmentAttachmentService = new AssignmentAttachmentService();
    import {AssignmentMixin} from "@/mixins/AssignmentMixin";

    export default {
        name: "Attachment",
        props: ['assignment_id'],
        mixins: [AssignmentMixin],
        computed: {
            assignment_id() {
                return this.$route.params.assignment_id;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },
            checkSize() {
                let max_size = 0;
                if (this.assignment.max_submission_size)
                    max_size = this.one_mb_size * this.assignment.max_submission_size;
                if (this.totalSize > max_size) {
                    return true;
                } else return false;

            },

        },
        watch: {
            images(newValue, oldValue) {
                if (newValue.length>0){
                    this.upload()
                }
            }
        },
        data() {
            return {
                active_statuses: [
                    {title: 'Active', value: "active"},
                    {title: 'In Active', value: "in_active"}
                ],
                file_types: [
                    {value: "ppt", title: 'pptx/ppt'},
                    {value: "doc", title: 'docx/doc'},
                    {value: "csv", title: 'csv/xlsx'},
                    {value: "mp3", title: 'mp3'},
                    {value: "mp4", title: 'mp4'},
                    {value: "pdf", title: 'pdf'},
                    {value: "png", title: 'png'},
                    {value: "jpeg", title: 'jpg/jpeg'},
                ],
                url: null,
                menu2: false,
                isBusy: false,
                mediaLoading: false,
                isDragging: false,
                imageFiles: ["jpg", "jpeg", "png", "gif"],
                dragCount: 0,
                files: [],
                images: [],
                dialog: false,
                error: false,
                absolute: true,
                overlay: false,
                medias: null,
                totalSize: 0,
                total: null,
                perPage: null,
                page: null,
                snackbar: false,
                one_mb_size: 2072576,
                text: "My timeout is set to 2000.",
                timeout: 2000,
                search: {
                    type: "submission",
                    student_id: null,
                }
            };
        },
        mounted() {
            this.search.student_id = this.currentUser.id;
            this.getMedia();
            this.getSingleAssignment(this.assignment_id);
            console.log(this.getFileSize(2072576))
        },
        methods: {

            checkType(type) {
                if (type.includes("image")) {
                    return true;
                } else return false;
            },
            showUrl(image) {
                this.url = image.path;
                this.dialog = true;
            },
            checkFileType(file) {
                let fileExtension = file.split(".")[1];
                let image = this.imageFiles.indexOf(fileExtension) > -1;
                return image;
            },
            OnDragEnter(e) {
                e.preventDefault();

                this.dragCount++;
                this.isDragging = true;

                return false;
            },
            OnDragLeave(e) {
                e.preventDefault();
                this.dragCount--;

                if (this.dragCount <= 0) this.isDragging = false;
            },
            onInputChange(e) {
                const files = e.target.files;

                Array.from(files).forEach(file => this.addImage(file));
            },
            onDrop(e) {
                e.preventDefault();
                e.stopPropagation();

                this.isDragging = false;

                const files = e.dataTransfer.files;

                Array.from(files).forEach(file => this.addImage(file));
            },
            addImage(file) {
                // console.log(file.size)
                // this.totalSize += file.size;
                // console.log(this.getFileSize(this.totalSize));
                // console.log(this.checkSize())
                const type = file.name.split(".")[1];
                if (file.type.match("image.*")) {
                    this.addFiles(file);
                } else if (type == "pdf") {
                    this.addFiles(file);
                } else if (type == "pptx") {
                    this.addFiles(file);
                } else if (type == "doc") {
                    this.addFiles(file);
                } else if (type == "docx") {
                    this.addFiles(file);
                } else if (type == "csv") {
                    this.addFiles(file);
                } else if (type == "xlsx") {
                    this.addFiles(file);
                } else if (type == "mp3") {
                    this.addFiles(file);
                } else if (type == "mp4") {
                    this.addFiles(file);
                } else {
                    // alert(`${file.name} is not an image`);
                    this.error = true;
                    // file.type.split('/')[1]
                    return;
                }
            },

            onCopy: function (e) {
                this.text = "You just copied URL ";
                this.snackbar = true;
            },
            onError: function (e) {
                this.text = "Failed to copy URL";
                this.snackbar = true;
            },
            addFiles(file) {
                this.error = false;
                this.files.push(file);

                const img = new Image(),
                    reader = new FileReader();

                reader.onload = e => this.images.push(e.target.result);

                reader.readAsDataURL(file);
            },
            deleteImg(id) {
                this.$confirm({
                    message: `Confirm delete file ?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            assignmentAttachmentService.delete(this.assignment_id, id).then(res => {
                                this.$snotify.success("Deleted");
                                this.getMedia();
                            });
                        }
                    }
                });
            },
            getFileSize(size) {
                const fSExt = ["Bytes", "KB", "MB", "GB"];
                let i = 0;
                while (size > 900) {
                    size /= 1024;
                    i++;
                }
                return `${Math.round(size * 100) / 100} ${fSExt[i]}`;
            },
            upload() {
                const formData = new FormData();
                this.isBusy = true;
                this.files.forEach(file => {
                    if (file) {
                        formData.append("file[]", file);
                        formData.append("type[]", 'submission');
                        formData.append("student_id[]", this.currentUser.id);
                        formData.append("size[]", file.size);
                        formData.append("name[]", file.name);
                        formData.append("type[]", file.name.split(".")[1]);
                    }
                });

                // alert("the total size uploaded is " + this.getFileSize(this.totalSize));
                assignmentAttachmentService.store(this.assignment_id, formData).then(res => {
                    this.isBusy = false;
                    this.$snotify.success("Uploaded");
                    this.$emit("refresh");
                    this.images = [];
                    this.files = [];
                    this.getMedia();
                });
            },
            getMedia(index = null) {
                this.mediaLoading = true;
                assignmentAttachmentService
                    .paginate(this.assignment_id, this.search, this.page)
                    .then(response => {
                        this.medias = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                    })
                    .catch((err) => {
                        // console.log(err)
                    }).finally(() => {
                    this.mediaLoading = false;
                });
            },
            resetFilter(index = null) {
                this.search = {
                    title: "",
                    type: "all"
                };
                this.getMedia();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .uploader {
        width: 100%;
        background: #eef5ff;
        color: #777;
        //padding: 40px 15px 102px - 30px;
        text-align: center;
        border-radius: 10px;
        //border: 1px dashed #696969 !important;
        font-size: 17px;
        position: relative;

        &.dragging {
            background: #fff;
            color: #2196f3;
            border: 1px dashed #696969 !important;

            .file-input label {
                background: #2196f3;
                color: #fff;
            }
        }

        i {
            font-size: 20px;
        }

        .file-input {
            width: 175px;
            margin: auto;
            height: 65px;
            position: relative;

            label,
            input {
                background: #2196f3;
                color: #fff;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                padding: 10px;
                border-radius: 4px;
                margin-top: 7px;
                cursor: pointer;
            }

            input {
                opacity: 0;
                z-index: -2;
            }
        }

        .images-preview {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: 20px;
            margin-left: -10px;
            margin-right: -10px;
            padding: 0 10px;


            .add-btn-wrapper {
                width: 100% !important;
                border: 1px dashed;
                display: flex;
                flex-flow: column wrap;
                align-items: center;
                justify-content: center;
                order: 0 !important;
            }

            .details {
                font-size: 12px;
                background: #fff;
                color: #000;
                display: flex;
                flex-direction: column;
                align-items: self-start;
                padding: 3px 6px;

                .name {
                    overflow: hidden;
                    height: 18px;
                    width: 100%;
                }
            }
        }

        .action-bar {
            width: 100%;
        }

        .action-bar i {
            font-size: 15px;
        }

        .upload-control {
            position: relative;
            width: 88%;
            background: #3cbd81;
            bottom: 0;
            left: 0;
            padding: 10px;
            padding-bottom: 10px;
            padding-bottom: 4px;
            top: 10px;
            margin: 0 auto;

            button,
            label {
                background: #fff;
                border: 1px solid #7b7b7b;
                border-radius: 3px;
                color: #111;
                font-size: 13px;
                cursor: pointer;
            }

            label {
                padding: 2px 5px;
                margin-right: 10px;
            }
        }
    }
</style>
